import { Link } from "react-router-dom";
import { ReactComponent as WalletIcon } from "../../wallet_icon.svg";
import styles from "./Footer.module.css";
import React from "react";

const Footer: React.FC = (): React.ReactElement => {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <ul className={styles.footerLinks}>
          <li>
            <Link to="/news">News</Link>
          </li>
          <li>
            <Link to="/bridge">Bridge</Link>
          </li>
          <li>
            <Link to="/coins/compass">HEDGE Compass</Link>
          </li>
          <li>
            <Link to="/coins/arbitrage">HEDGE Arbitrage</Link>
          </li>
          <li>
            <Link to="/redeem">Redeem</Link>
          </li>
          <li>
            <Link to="/HEDGE_Whitepaper.pdf" target="_blank">
              Whitepaper
            </Link>
          </li>
        </ul>
        <div className={styles.buttonsWrapper}>
          
          <Link to="/buy">
          <button className={styles.buyButton}>Buy</button>
          </Link>
        </div>
        <div className={styles.contactsWrapper}>
          <img
            style={{ marginBottom: "39px" }}
            src="/full_logo_white.svg"
            alt="Hedge"
          />

          <div
            style={{
              display: "flex",
              width: "150px",
              justifyContent: "space-between",
              marginBottom: "39px",
            }}
          >
            

            <a href="https://www.facebook.com/profile.php?id=61558728571716" target="_blank" rel="noopener noreferrer">
                <img src="/fb_icon_white.svg" alt="facebook" width="30" height="30" style={{ marginRight: '40px' }}/>
            </a>
            <a href="https://x.com/HedgeCoins123" target="_blank" rel="noopener noreferrer">
                <img src="/twitter_icon_white.svg" alt="twitter" width="30" height="30" style={{ marginRight: '40px' }}/>
            </a>
            <a href="http://www.youtube.com/@HedgeCoins" target="_blank" rel="noopener noreferrer">
                <img src="/yt_icon_white.svg" alt="youtube" width="30" height="30" style={{ marginRight: '40px' }}/>
            </a>           
            <a href="https://www.instagram.com/hedgecoins.io/profilecard/?igsh=MWM3dXVpdnZrZTgxeg==" target="_blank" rel="noopener noreferrer">
                <img src="/instagram.png" alt="instagram" width="30" height="30" style={{ marginRight: '40px' }}/>
            </a>
            <a href="https://t.me/+U9oODtIp2Fs0OGE0" target="_blank" rel="noopener noreferrer">
                <img src="/telegram.png" alt="telegram" width="30" height="30" style={{ marginRight: '40px' }}/>
            </a>
            

            

            </div>
          <a className={styles.email} href="mailto:contact@hedgecoins.io">
            <img src="/mail_icon_white.svg" alt="email" />
            <span>contact@hedgecoins.io</span>
          </a>
        </div>
      </div>
      <div className={styles.bottomWrapper}>
        <p className={styles.copyright}>
          COPYRIGHT © 2023-2024 HEDGE HOLDINGS LIMITED - ALL RIGHTS RESERVED.
        </p>

        <div className={styles.smallTextWrapper}>
          <Link className={styles.smallText} to="/disclaimer">
            Terms and Conditions
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
