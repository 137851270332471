// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsCard_wrapper__y7PD\\+ {
  max-width: 378px;
  max-height: 221px;
  width: 100%;
}

.NewsCard_img__c0tGZ {
  display: block;
  margin-bottom: 10px;
  border-radius: 12px;
  max-height: 221px;
  max-width: 378px;
  width: 100%;
  min-height: 221px;
  object-fit: cover; 
  /* ss added as in article.module.css. contain produces letterboxing, 
  cover increases size to fit but clips, fill streches and warps the image 
  The container is roughly 16:9 aspect ratio. 
  Images should be larger than 221 x 378 */
}

.NewsCard_title__7fSsV {
  color: #333;
  font-family: Inter, Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 10px;
}

.NewsCard_text__dYk5a {
  height: 47px;
  overflow: hidden;
  color: #5c5c5c;
  text-overflow: ellipsis;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 5px;
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
`, "",{"version":3,"sources":["webpack://./src/components/NewsCard/NewsCard.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB;;;0CAGwC;AAC1C;;AAEA;EACE,WAAW;EACX,qCAAqC;EACrC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,uBAAuB;EACvB,qCAAqC;EACrC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;AACtB,qBAAqB;AACrB,4BAA4B;AAC5B","sourcesContent":[".wrapper {\n  max-width: 378px;\n  max-height: 221px;\n  width: 100%;\n}\n\n.img {\n  display: block;\n  margin-bottom: 10px;\n  border-radius: 12px;\n  max-height: 221px;\n  max-width: 378px;\n  width: 100%;\n  min-height: 221px;\n  object-fit: cover; \n  /* ss added as in article.module.css. contain produces letterboxing, \n  cover increases size to fit but clips, fill streches and warps the image \n  The container is roughly 16:9 aspect ratio. \n  Images should be larger than 221 x 378 */\n}\n\n.title {\n  color: #333;\n  font-family: Inter, Arial, sans-serif;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 140%;\n  margin-bottom: 10px;\n}\n\n.text {\n  height: 47px;\n  overflow: hidden;\n  color: #5c5c5c;\n  text-overflow: ellipsis;\n  font-family: Inter, Arial, sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 160%;\n  margin-bottom: 5px;\n  display: -webkit-box;\n-webkit-line-clamp: 2;\n-webkit-box-orient: vertical;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NewsCard_wrapper__y7PD+`,
	"img": `NewsCard_img__c0tGZ`,
	"title": `NewsCard_title__7fSsV`,
	"text": `NewsCard_text__dYk5a`
};
export default ___CSS_LOADER_EXPORT___;
