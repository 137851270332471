import { useNavigate, useRoutes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Arbitrage from "./pages/Arbitrage/Arbitrage";
import Article from "./pages/Article/Article";
import Buy from "./pages/Buy/Buy";
import BuyBalance from "./pages/BuyBalance/BuyBalance";
import Compass from "./pages/Compass/Compass";
import Disclaimer from "./pages/Disclaimer/Disclaimer";
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import Redeem from "./pages/Redeem/Redeem";
import Transfer from "./pages/Transfer/Transfer";
import RedeemBalance from "./pages/RedeemBalance/RedeemBalance";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useAccount, useConfig, useDisconnect } from "wagmi";
import { setNetwork, setSelectedConfig, setToken } from "./services/GlobalSlice";
import { config, configBinance } from "./config/config";
import { useSignMessage } from "wagmi";
import {
  useGetSignMessageQuery,
  useWalletLoginMutation,
} from "./services/Auth";
import { useAccountEffect } from "wagmi";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import ComingSoon from "./pages/ComingSoon/ComingSoon";

const BRIDGE = false;

function App() {
  const dispatch = useDispatch();
  const { address, chainId, isDisconnected } = useAccount();

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      dispatch(setToken(token))
    }
  }, [dispatch, setToken])

  useEffect(() => {
    if (chainId === 11155111 || chainId === 1) {
      dispatch(setSelectedConfig(config));
    } else {
      dispatch(setSelectedConfig(configBinance));
    }
  }, [chainId]);

  const blockChainNetwork = function () {
    if (chainId === 11155111 || chainId === 1) {
      return 'Ethereum';
    } else {
      return 'Binance';
    }
  };

  useEffect(() => {
    let network = blockChainNetwork();
    localStorage.setItem('Network', network);
    dispatch(setNetwork(network));
  }, [chainId]);

  const [signInWallet, setSignInWallet] = useState<boolean>(false);
  const { disconnect } = useDisconnect();
  const {
    data: signMessageData,
    error: signMessageError,
    signMessage: ethSignMessage,
  } = useSignMessage();

  const { data: signMessage } = useGetSignMessageQuery({});
  const [walletLogin] = useWalletLoginMutation();
  const navigate = useNavigate();
  const Config = useConfig();

  useAccountEffect({
    onConnect({ isReconnected }) {
      if (!isReconnected) {
        ethSignMessage({ message: signMessage?.message });
      }
    },
    onDisconnect() {
      setSignInWallet(false);
      localStorage.removeItem("token");
    },
  });

  useEffect(() => {
    if (isDisconnected) {
      localStorage.removeItem("token")
    }
  }, [isDisconnected])

  const handleEthLogin = useCallback(async () => {
    try {
      const data = {
        signature: signMessageData,
      };
      const response = await walletLogin(data).unwrap();
      localStorage.setItem('token', response?.token);
      toast.success("Account updated!!");
      dispatch(setToken(response?.token));
      Cookies.set("session_token", response?.token);
      navigate("/buy-balance");
      setSignInWallet(true);
    } catch (error: any) {
      toast.error(error?.data?.error?.message);
      setSignInWallet(false);
      disconnect();
      localStorage.clear()
    }
  }, [signMessageData, walletLogin, dispatch, navigate, disconnect]);

  useEffect(() => {
    if (signMessageError) {
      setSignInWallet(false);
      disconnect();
    }
  }, [disconnect, signMessageError]);

  useEffect(() => {
    if (signMessageData && !signInWallet && !isDisconnected) {
      handleEthLogin();
    }
  }, [signMessageData, handleEthLogin, address, signInWallet]);

  const routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <Home /> },
        { path: "buy", element: <Buy /> },
        { path: "redeem", element: <Redeem /> },
        { path: "coins", element: <h1>Coins</h1> },
        { path: "bridge", element: BRIDGE ? <Transfer /> : <ComingSoon /> },
        { path: "resources", element: <h1>Resources</h1> },
        { path: "disclaimer", element: <Disclaimer /> },
        { path: "transfer", element: BRIDGE ? <Transfer /> : <ComingSoon /> },
        { path: "buy-balance", element: <BuyBalance /> },
        { path: "redeem-balance", element: <RedeemBalance /> },
      ],
    },
    {
      path: "news",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <News />,
        },
        { path: "article/:id", element: <Article /> },
      ],
    },
    {
      path: "coins",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <h1>Coins</h1>,
        },
        { path: "compass", element: <Compass /> },
        { path: "arbitrage", element: <Arbitrage /> },
      ],
    },
  ]);
  return routes;
}

export default App;
